<template>
  <!-- content @s -->
  <div class="nk-content nk-content-lg nk-content-fluid">
    <div class="container-xl wide-lg">
      <div class="nk-content-inner">
        <div class="nk-content-body">
          <a class="logo" href="https://chio-moreno.com">
            <div class="info-holder text-center">
              <img src="@/assets/images/logo.png" alt="">
            </div>
          </a>
          <div class="nk-block-head text-center">
            <div class="nk-block-head-content">
              <div class="nk-block-head-content">
                <div class="nk-block-des text-white font-italic mt-4">
                  <p>No pierdas la oportunidad de aprender. Master internacional, experta en diseño, creadora de su propia técnica y ganadora del premio a mejor #BoldBrowsMaster 2019</p>
                </div>
              </div>
            </div>
          </div><!-- nk-block -->
          <div class="nk-block">
              <div class="plan-iv-list nk-slider nk-slider-s2">
                <ul id="macy-container" class="plan-list row">
                  <li v-for="curso in cursos" :key="curso.id_curso" class="plan-item">
                    <div class="plan-item-card">
                      <div class="plan-item-head">
                        <div class="plan-item-heading">
                          <h4 class="plan-item-title card-title text-primary title">{{curso.titulo}}</h4>
                          <!--
                          <p class="sub-text">Enjoy entry level of invest & earn money.</p>
                          -->
                        </div>
                        <div class="plan-item-summary card-text card-image d-flex align-items-center">
                          <router-link :to="'/curso/'+curso.slug" title="ACCEDER AL CURSO">
                            <img :src="curso.portada" :alt="curso.titulo" />
                          </router-link>
                        </div>
                      </div>
                      <div class="plan-item-body">
                        <div class="plan-item-desc card-text">
                          <p class="text-justify">{{curso.descripcion}}</p>
                          <div class="plan-item-action mt-3">
                            <a :href="curso.pdf" target="_blank" class="btn btn-outline-primary btn-block"><em class="text-danger ni icon ni-file-pdf mr-1"></em> VER DETALLES</a>
                            <router-link :to="'/curso/'+curso.slug" class="btn btn-primary btn-block">ACCEDER AL CURSO</router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li><!-- .plan-item -->
                </ul><!-- .plan-list -->
              </div>
          </div><!-- nk-block -->
        </div>
      </div>
    </div>
  </div>
  <!-- content @e -->
  <!-- footer @s -->
  <div class="nk-footer nk-footer-fluid bg-white text-dark">
    <div class="container-xl wide-lg">
      <div class="nk-footer-wrap">
        <div class="col-12 text-center mb-3">
          Consulta nuestro <a href="/assets/pdf/aviso-privacidad.pdf" class="text-info" target="_blank">aviso de privacidad</a> y nuestros <a href="/assets/pdf/terminos-y-condiciones.pdf" class="text-info" target="_blank">términos y condiciones</a>
        </div>
        <div class="col-12 text-center">
          © {{year}} | Hecho con <i class="ni ni-coffee-fill"></i> y <i class="ni ni-heart-fill text-danger"></i> en <a href="https://tekoestudio.com" class="text-info" target="_blank">Teko Estudio</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, nextTick } from 'vue';
import Swal from "sweetalert2";
import {useApi} from "@/use/useApi";
import {useRouter} from "vue-router";
import * as Macy from 'macy';
export default defineComponent({
  name: "Index",
  setup(){

    const { loading, make } = useApi();
    const router = useRouter();

    const state = reactive({
      cursos: []
    });

    //Methods
    async function get(){
      loading.message = 'Obteniendo información';
      let {data} = await make('cursos/get-all', {});
      if(data === null){
        router.push('/');
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          router.push('/');
          Swal.fire('Error', data.message, 'error');
        } else {
          state.cursos = data.cursos;
          await nextTick();
          window.macy = Macy({
            container: '#macy-container',
            trueOrder: false,
            waitForImages: false,
            margin: 0,
            columns: 3,
            breakAt: {
              1200: 3,
              940: 2,
              520: 1,
              400: 1
            }
          });
        }
      }
    }

    get();

    return { ...toRefs(state) }
  }
});
</script>

<style>
.logo {
  max-width: 350px;
  display: block;
  margin: 3em auto 1em auto;
}
body.bg-lighter {
  background-color: rgba(207, 178, 200, 0.82) !important;
}
.plan-item {
  padding: 5px;
}

.card-image {
  min-height: 220px;
}
h4, h6, .overline-title, .btn {
  font-weight: 500;
}

.text-primary {
  color: #000000 !important;
}

.btn-primary {
  background: #b488a9 !important;
  border-color: #b488a9 !important;
  color: #FFFFFF !important;
  font-weight: normal;
}

.btn-primary:hover {
  background: #a06992 !important;
  border-color: #a06992 !important;
}

.btn-outline-primary {
  border: 0.5px solid #888888 !important;
  color: #333333 !important;
  font-weight: normal;
}

.btn-outline-primary:hover {
  background: #333333 !important;
  color: #FFFFFF !important;
  font-weight: normal;
}

.btn-outline-primary:hover em {
  color: #FFFFFF !important;
}
</style>